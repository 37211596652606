import React from 'react';
import {Form, ProgressBar} from "react-bootstrap";

const LoadingData = ({ pods, nodes, deployments, statefulSets } : { pods: boolean, nodes: boolean, deployments: boolean, statefulSets: boolean}) => {
	const nTotal = 3;
	const nLoaded = [pods, nodes, deployments, statefulSets]
		.map((x: boolean) => x ? 1 : 0)
		.reduce((acc: number, cur: number) => acc + cur, 0);
	const progress = nLoaded / nTotal * 100;

	return <div>
		<h1>Loading...</h1>
		<Form.Switch
			readOnly
			label="Nodes"
			checked={nodes}
		/>
		<Form.Switch
			readOnly
			label="Pods"
			checked={pods}
		/>
		<Form.Switch
			readOnly
			label="Deployments"
			checked={deployments}
		/>
		<Form.Switch
			readOnly
			label="StatefulSets"
			checked={statefulSets}
		/>
		<ProgressBar now={progress} />
	</div>;
};

export default LoadingData;
