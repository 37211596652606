import PodEntity, {ContainerEntity, ContainerResourceCategory} from "./entity/PodEntity";
import NodeEntity from "./entity/NodeEntity";
import {sortByProp} from "./utils";

const isPodCompleted = (pod : PodEntity) => pod?.containers.every((container: ContainerEntity) => container?.state?.terminatedReason === 'Completed') ?? false;
const isPodReady = (pod: PodEntity) => pod.containers.every((container: ContainerEntity) => container.ready);
const sortPods = (pods : PodEntity[],
				  by : ((p: PodEntity) => any) = (p: PodEntity) => p.name) =>
	pods?.sort((a: PodEntity, b: PodEntity) => {
		if (isPodCompleted(a)) {
			if (isPodCompleted(b)) {
				if (by(a) < by(b)) {
					return -1;
				} else if (by(a) > by(b)) {
					return 1;
				}

				return 0;
			}

			return 1;
		} else if (isPodCompleted(b)) {
			return -1;
		}

		if (by(a) < by(b)) {
			return -1;
		} else if (by(a) > by(b)) {
			return 1;
		}

		return 0;
})
const getPodSortingSelector = (property: string) => {
	switch (property) {
		case 'name':
			return (p: PodEntity) => p.name;
		case 'memory_usage':
			return (p: PodEntity) => -sumMemory(p, ContainerResourceCategory.USAGE);
		case 'memory_request':
			return (p: PodEntity) => -sumMemory(p, ContainerResourceCategory.REQUEST);
		case 'memory_limit':
			return (p: PodEntity) => -sumMemory(p, ContainerResourceCategory.LIMIT);
		case 'cpu_usage':
			return (p: PodEntity) => -sumCpu(p, ContainerResourceCategory.USAGE);
		case 'cpu_request':
			return (p: PodEntity) => -sumCpu(p, ContainerResourceCategory.REQUEST);
		case 'cpu_limit':
			return (p: PodEntity) => -sumCpu(p, ContainerResourceCategory.LIMIT);
		default:
			throw new Error('Invalid sorting property: ' + property)
	}
}
const sumCpu = (pod : PodEntity, category : ContainerResourceCategory = ContainerResourceCategory.USAGE) =>
	pod?.containers.reduce((acc, c) => {
		if (c.resources?.[category]?.cpu == null) {
			return null;
		}

		return acc + c.resources[category].cpu;
	}, 0);
const sumMemory = (pod : PodEntity, category : ContainerResourceCategory = ContainerResourceCategory.USAGE) =>
	pod?.containers.reduce((acc, c) => {
		if (c.resources?.[category]?.memory == null) {
			return null;
		}

		return acc + c.resources[category].memory;
	}, 0);
const roundToDecimalPlaces = (x: number, decimalPlaces: number = 2) => parseFloat(String(x)).toFixed(decimalPlaces);
const getMemoryAvailableOnNode = (node: NodeEntity) : number | null => {
	if (node?.resources?.usage?.memory == null || node?.resources?.allocatable?.memory == null) {
		return null;
	}

	return node.resources.allocatable.memory - node.resources.usage.memory;
}

const LABELS_I_DONT_CARE_ABOUT : (string | RegExp)[] = [/^pod-template-.+/, /^controller-revision-.+/, 'controller-uid', 'job-name', /^app\.kubernetes\.io\/.+/];
const filterOutLabelsIDontCareAbout = ([ key ] : [ key : string, value : string ]) => !LABELS_I_DONT_CARE_ABOUT.some(dontCare => key.match(dontCare));
const getLabelPairsForSinglePod = (pod : PodEntity) : [ key : string, value : string ][] => Object.entries(pod.labels).filter(filterOutLabelsIDontCareAbout);
const getLabelPairsForSinglePodAsString = (pod : PodEntity) : string[] => getLabelPairsAsString([pod]);
const getLabelPairs = (pods : PodEntity[]) : [ key : string, value : string ][] => pods.map(getLabelPairsForSinglePod)
	.flatMap(x => x)
	.reduce((acc : [ key : string, value : string ][], cur : [ key : string, value : string ]) => {
		if (acc.some(([ k, v ]) => k === cur[0] && v === cur[1])) {
			return acc;
		}

		return [...acc, cur];
	}, []).filter(filterOutLabelsIDontCareAbout)
	.sort(sortByProp(x => x[0]));
const getLabelPairsAsString = (pods : PodEntity[]) => getLabelPairs(pods).map(([ k, v ]) => `${k}=${v}`).sort();

export { isPodCompleted };
export { isPodReady };
export { sortPods };
export { getPodSortingSelector };
export { sumCpu };
export { sumMemory };
export { roundToDecimalPlaces };
export { getMemoryAvailableOnNode };
export { getLabelPairsForSinglePod };
export { getLabelPairsForSinglePodAsString };
export { getLabelPairs };
export { getLabelPairsAsString };