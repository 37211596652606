import {createContext, Dispatch, SetStateAction} from 'react';
import PodEntity from "./entity/PodEntity";
import NodeEntity from "./entity/NodeEntity";
import DeploymentEntity from "./entity/DeploymentEntity";
import StatefulSetEntity from "./entity/StatefulSetEntity";
import PodSetEntity from "./entity/PodSetEntity";

interface AppContextProperties {
	loading: boolean,
	setLoading: Dispatch<SetStateAction<boolean>>,
	pods: PodEntity[],
	nodes: NodeEntity[],
	deployments: DeploymentEntity[],
	statefulSets: StatefulSetEntity[],
	podSets: PodSetEntity[],
	podSetNames: string[],
	podsByPodSetName : Record<string, PodEntity[]>,
	hoverPodSetName: string | null,
	setHoverPodSetName: Dispatch<SetStateAction<string>>,
	availableMemoryByNode: Record<string, number | null>,
	namespaces: string[],
	selectedNamespace: string | null,
	setSelectedNamespace: (x : string) => void
	selectedLabelPair: string | null,
	setSelectedLabelPair: (x : string) => void
}

const AppContext = createContext({
	loading: false,
	setLoading: () => void 0,
	pods: void 0,
	nodes: void 0,
	deployments: void 0,
	statefulSets: void 0,
	podSets: void 0,
	podSetNames: void 0,
	podsByPodSetName: void 0,
	hoverPodSetName: void 0,
	setHoverPodSetName: () => void 0,
	availableMemoryByNode: void 0,
	namespaces: void 0,
	selectedNamespace: void 0,
	setSelectedNamespace: () => void 0,
	selectedLabelPair: void 0,
	setSelectedLabelPair: () => void 0
} as AppContextProperties);

export default AppContext;