import React, {useContext, useMemo} from 'react';
import {Alert, Badge, Col, ProgressBar} from "react-bootstrap";
import NodeEntity from "../entity/NodeEntity";
import AppContext from "../AppContext";
import PodEntity, {ContainerEntity, ContainerResourceCategory} from "../entity/PodEntity";
import Pod from './Pod';
import {
	getLabelPairsForSinglePodAsString, isPodCompleted, isPodReady,
	roundToDecimalPlaces,
	sumCpu,
	sumMemory
} from "../pod_utils";
import './Node.scss';

const CpuProgressBar = ({usage, total, allocatable, nodeName}: {usage?: number, total?: number, allocatable?: number, nodeName: string}) => {
	const { pods } = useContext(AppContext);

	if (usage == null || total == null || allocatable == null) {
		return <span />
	}

	const usagePerAllocatable = usage / allocatable;
	const percentage = usagePerAllocatable * 100.0;
	const accountedFor: number = pods.filter((pod: PodEntity) => pod.nodeName === nodeName)
		.map((pod: PodEntity) => sumCpu(pod, ContainerResourceCategory.USAGE))
		.reduce((acc, cur) => acc + cur, 0);
	const accountedForPercentage = accountedFor / allocatable * 100.0;

	const style = (() => {
		if (percentage > 90) {
			return 'danger';
		} else if (percentage > 75) {
			return 'warning';
		} else if (percentage < 25) {
			return 'success';
		}

		return 'primary';
	})();

	const tooltip: string = `Reported usage: ${roundToDecimalPlaces(percentage)}%
Accounted for: ${roundToDecimalPlaces(accountedForPercentage)}%
---
Warning ≥ 75%
Danger ≥ 90%`;

	return <ProgressBar
		striped
		variant={style}
		now={percentage}
		title={tooltip}
		label={roundToDecimalPlaces(percentage, 2) + '%'} />;
}

const MemoryProgressBar = ({usage, total, allocatable, nodeName}: {usage?: number, total?: number, allocatable?: number, nodeName: string}) => {
	const hasData: boolean = !(usage == null || total == null || allocatable == null);

	const { pods } = useContext(AppContext);
	const podsOnNode: PodEntity[] = useMemo(
		() => !hasData ? [] : pods.filter((pod: PodEntity) => pod.nodeName === nodeName),
		[pods, hasData, nodeName]);
	const accountedFor: number = useMemo(() => !hasData ? 0 : podsOnNode
		.map((pod: PodEntity) => sumMemory(pod, ContainerResourceCategory.USAGE))
		.reduce((acc, cur) => acc + cur, 0), [pods, hasData, nodeName]);
	const sumRequested: number = useMemo(() => !hasData ? 0 : podsOnNode
		.map((pod: PodEntity) => sumMemory(pod, ContainerResourceCategory.REQUEST))
		.reduce((acc, cur) => acc + cur, 0), [pods, hasData, nodeName]);
	const sumLimits: number = useMemo(() => !hasData ? 0 : podsOnNode
		.map((pod: PodEntity) => sumMemory(pod, ContainerResourceCategory.LIMIT))
		.reduce((acc, cur) => acc + cur, 0), [pods, hasData, nodeName]);

	if (!hasData) {
		return <span />
	}

	const usagePerAllocatable = usage / allocatable;
	const percentage = usagePerAllocatable * 100.0;
	const usageMb = usage / 1024.0/ 1024.0;

	const style = (() => {
		if (percentage > 95) {
			return 'danger';
		} else if (percentage > 90) {
			return 'warning';
		} else if (percentage < 70) {
			return 'success';
		}

		return 'primary';
	})();

	const tooltip: string = `Reported usage: ${roundToDecimalPlaces(usageMb, 2)}MiB
Accounted for: ${roundToDecimalPlaces(accountedFor / 1024.0 / 1024.0)}MiB
Sum of requests: ${roundToDecimalPlaces(sumRequested / 1024.0 / 1024.0)}MiB
Sum of limits: ${roundToDecimalPlaces(sumLimits / 1024.0 / 1024.0)}MiB
---
Warning ≥ ${roundToDecimalPlaces((allocatable * .90) / 1024.0 / 1024.0)}MiB
Danger ≥ ${roundToDecimalPlaces((allocatable * .95) / 1024.0 / 1024.0)}MiB
Allocatable: ${roundToDecimalPlaces(allocatable / 1024.0 / 1024.0)}MiB
Total: ${roundToDecimalPlaces(total / 1024.0 / 1024.0)}MiB`;

	return <ProgressBar
		variant={style}
		now={percentage}
		title={tooltip}
		label={roundToDecimalPlaces(usageMb, 2) + 'MiB'} />;
}

const NumberOfPodsBadge = ({ node } : { node: NodeEntity }) => {
	const { pods: allPods } = useContext(AppContext);
	const pods: PodEntity[] = allPods.filter((pod: PodEntity) => pod.nodeName === node.name)
		.filter((pod: PodEntity) => !isPodCompleted(pod));
	const nPods = pods.length;
	const nPodsReady = pods.filter(isPodReady).length;

	if (nPodsReady !== nPods) {
		return <Badge variant="warning" className="node-n-pods-badge">{nPodsReady}/{nPods}</Badge>;
	}

	return <Badge variant="success" className="node-n-pods-badge">{nPodsReady}/{nPods}</Badge>;
}

const Node = ({node} : { node: NodeEntity }) => {
	const { name, resources } = node;
	const { pods: allPods, selectedNamespace, selectedLabelPair } = useContext(AppContext);

	if (!allPods) {
		return <Col>
			<Alert variant="danger">
				Failed to retrieve pods for node <kbd>{name}</kbd>.
			</Alert>
		</Col>
	}

	const pods: PodEntity[] = allPods.filter((pod: PodEntity) => pod.nodeName === name);

	return <Col className="node">
		<h1>{name} <NumberOfPodsBadge node={node} /></h1>
		<CpuProgressBar
			nodeName={node.name}
			usage={resources?.usage?.cpu}
			total={resources?.total?.cpu}
			allocatable={resources?.allocatable?.cpu} />
		<MemoryProgressBar
			nodeName={node.name}
			usage={resources?.usage?.memory}
			total={resources?.total?.memory}
			allocatable={resources?.allocatable?.memory} />
		<div className="pods">
			{pods.filter(p => selectedNamespace == null || p.namespace === selectedNamespace)
				.filter(p => selectedLabelPair == null || getLabelPairsForSinglePodAsString(p).includes(selectedLabelPair))
				.map(p => <Pod key={p?.name} pod={p} />)}
		</div>
	</Col>;
}

export default Node;
