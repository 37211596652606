import React, {useCallback, useContext, useMemo, useState} from 'react';
import Promise from 'bluebird';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import Node from "./component/Node";
import AppContext from "./AppContext";
import {API_ENDPOINT, REFRESH_DATA_INTERVAL} from "./config";
import NodeEntity from "./entity/NodeEntity";
import PodEntity, {ContainerResourceCategory} from "./entity/PodEntity";
import {Col, Container, Form, ProgressBar, Row} from "react-bootstrap";
import DeploymentEntity from "./entity/DeploymentEntity";
import useInterval from "use-interval";
import LoadingData from "./component/LoadingData";
import {
	getLabelPairsAsString,
	getLabelPairsForSinglePodAsString,
	getMemoryAvailableOnNode, getPodSortingSelector,
	roundToDecimalPlaces,
	sortPods,
	sumCpu,
	sumMemory
} from "./pod_utils";
import {parseResponse, sortByStringLength} from "./utils";
import {sortDeployments} from "./deployment_utils";
import {sortStatefulSets} from "./statefulset_utils";
import StatefulSetEntity from "./entity/StatefulSetEntity";
import PodSetEntity from "./entity/PodSetEntity";
import {sortNodes} from "./node_utils";
import KubernetesEntity from "./entity/KubernetesEntity";
import {useLocalStorage} from "./useCache";

const ResourceUsageBars = () => {
	const { pods: allPods, selectedNamespace, selectedLabelPair, nodes } = useContext(AppContext);

	const filteredPods: PodEntity[] = useMemo(() => allPods.filter(p => selectedNamespace == null || p.namespace === selectedNamespace)
		.filter(p => selectedLabelPair == null || getLabelPairsForSinglePodAsString(p).includes(selectedLabelPair)), [allPods, selectedNamespace, selectedLabelPair]);

	const memoryRequest = useMemo(() =>
		filteredPods.map(p => sumMemory(p, ContainerResourceCategory.REQUEST))
			.reduce((acc, cur) => acc + cur, 0), [filteredPods]);
	const memoryUsage = useMemo(() =>
		filteredPods.map(p => sumMemory(p, ContainerResourceCategory.USAGE))
			.reduce((acc, cur) => acc + cur, 0), [filteredPods]);
	const memoryLimit = useMemo(() =>
		filteredPods.map(p => sumMemory(p, ContainerResourceCategory.LIMIT))
			.reduce((acc, cur) => acc + cur, 0), [filteredPods]);
	const combinedAllocatableMemory = useMemo(() =>
		nodes.map(n => n.resources.allocatable.memory)
			.reduce((acc, cur) => acc + cur, 0), [nodes]);

	const cpuUsage = useMemo(() =>
		filteredPods.map(p => sumCpu(p, ContainerResourceCategory.USAGE))
			.reduce((acc, cur) => acc + cur, 0), [filteredPods]);
	const combinedAllocatableCpu = useMemo(() =>
		nodes.map(n => n.resources.allocatable.cpu)
			.reduce((acc, cur) => acc + cur, 0), [nodes]);
	const cpuUsagePercentage = cpuUsage / combinedAllocatableCpu * 100.0;

	return <div>
		<ProgressBar
			now={memoryRequest / combinedAllocatableMemory * 100.0}
			title={`${roundToDecimalPlaces(memoryRequest / 1024.0 / 1024.0)}MiB requested`}
			label={`${roundToDecimalPlaces(memoryRequest / 1024.0 / 1024.0)}MiB requested`}
			variant="success"
		/>
		<ProgressBar
			now={memoryUsage / combinedAllocatableMemory * 100.0}
			title={`${roundToDecimalPlaces(memoryUsage / 1024.0 / 1024.0)}MiB used`}
			label={`${roundToDecimalPlaces(memoryUsage / 1024.0 / 1024.0)}MiB used`}
			variant="primary"
		/>
		<ProgressBar
			now={memoryLimit / combinedAllocatableMemory * 100.0}
			title={`${roundToDecimalPlaces(memoryLimit / 1024.0 / 1024.0)}MiB limit`}
			label={`${roundToDecimalPlaces(memoryLimit / 1024.0 / 1024.0)}MiB limit`}
			variant="danger"
		/>
		<ProgressBar
			now={cpuUsagePercentage}
			title={`${roundToDecimalPlaces(cpuUsagePercentage)}% used`}
			label={`${roundToDecimalPlaces(cpuUsagePercentage)}% used`}
			striped
			variant="info"
		/>
	</div>;
};

const App = () => {
	const [initialLoadCompleted, setInitialLoadCompleted] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [nodes, setNodes] = useState<NodeEntity[]>(null);
	const [pods, setPods] = useState<PodEntity[]>(null);
	const [deployments, setDeployments] = useState<DeploymentEntity[]>(null);
	const [statefulSets, setStatefulSets] = useState<StatefulSetEntity[]>(null);
	const [hoverPodSetName, setHoverPodSetName] = useState<string | null>(null);
	const [selectedNamespace, setSelectedNamespace] = useLocalStorage<string | null>('selected_namespace', null);
	const [selectedLabelPair, setSelectedLabelPair] = useLocalStorage<string | null>('selected_label_pair', null);
	const [selectedSortingProperty, setSelectedSortingProperty] = useLocalStorage<string>('selected_sorting_property', 'name');

	const podSortingSelector: (p: PodEntity) => any = getPodSortingSelector(selectedSortingProperty);
	const podsSorted = useMemo(
		() => sortPods(pods, podSortingSelector),
		[pods, selectedSortingProperty, podSortingSelector])

	const podSets: PodSetEntity[] = useMemo(() => [...deployments ?? [], ...statefulSets ?? []]
		.filter(x => !!x)
		.sort(sortByStringLength(x => x.name)), [deployments, statefulSets]);
	const podSetNames = useMemo(() => podSets?.map(x => x.name), [podSets]);
	const podsByPodSetName : Record<string, PodEntity[]> = useMemo(() => pods?.reduce(
		(acc: Record<string, PodEntity[]>, p : PodEntity) => {
			const matchingPodSet: PodSetEntity = podSets.find(podSet => p.name.startsWith(podSet.name));
			if (!matchingPodSet) {
				return acc;
			}
			const matchingPodSetName: string = matchingPodSet.name;

			return {
				...acc,
				[matchingPodSetName]: [
					...(acc[matchingPodSetName] ?? []),
					p
				]
			}
		}, {}) ?? {}, [pods, podSets]);
	const availableMemoryByNode : Record<string, number | null> = useMemo(() => nodes?.reduce(
		(acc: Record<string, number | null>, cur: NodeEntity) => ({
			...acc,
			[cur.name]: getMemoryAvailableOnNode(cur)
		}), {}
	), [nodes]);
	const namespaces: string[] = useMemo(() => Array.from(new Set([...pods ?? [], ...podSets ?? []]
		.map((e: KubernetesEntity) => e.namespace))), [pods, podSets]);
	const labelPairs: string[] | null = useMemo(() => pods == null ? null : getLabelPairsAsString(pods), [pods]);

	const loadData = useCallback(() => {
		setLoading(true);
		Promise.all([
			fetch(`${API_ENDPOINT}node`)
				.then(parseResponse)
				.then(sortNodes)
				.then(setNodes),
			fetch(`${API_ENDPOINT}pod`)
				.then(parseResponse)
				.then(setPods),
			fetch(`${API_ENDPOINT}deployment`)
				.then(parseResponse)
				.then(sortDeployments)
				.then(setDeployments),
			fetch(`${API_ENDPOINT}statefulset`)
				.then(parseResponse)
				.then(sortStatefulSets)
				.then(setStatefulSets)
		])
			.then(() => setLoading(false))
			.then(() => {
				if (initialLoadCompleted) {
					return null;
				}

				return Promise.delay(500)
					.then(() => setInitialLoadCompleted(true));
			})
			.catch(window.alert)
			.then(() => setLoading(false));
	}, [setNodes, setPods, setDeployments, setStatefulSets, setLoading,
		initialLoadCompleted, setInitialLoadCompleted]);

	useInterval(() => loadData(), REFRESH_DATA_INTERVAL, true);

	if (!initialLoadCompleted || !nodes || !pods || !deployments || !statefulSets) {
		return <Container>
			<LoadingData
				pods={!!pods}
				nodes={!!nodes}
				deployments={!!deployments}
				statefulSets={!!statefulSets} />
		</Container>;
	}

	return (
		<AppContext.Provider value={{
			loading,
			setLoading,
			nodes,
			pods: podsSorted,
			deployments,
			statefulSets,
			podSets,
			podSetNames,
			podsByPodSetName,
			hoverPodSetName,
			setHoverPodSetName,
			availableMemoryByNode,
			namespaces,
			selectedNamespace,
			setSelectedNamespace,
			selectedLabelPair,
			setSelectedLabelPair
		}}>
			<Container fluid>
				<Row sm={6}>
					<Form.Group as={Col}>
						<Form.Label>Namespace:</Form.Label>
						<Form.Control
							as="select"
							onChange={e => setSelectedNamespace(e.target.value || null)}
							value={selectedNamespace ?? ''}>
							<option value="">All namespaces</option>
							<option disabled>---</option>
							{(namespaces ?? []).map(n => <option key={n} value={n}>{n}</option>)}
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Label:</Form.Label>
						<Form.Control
							as="select"
							onChange={e => setSelectedLabelPair(e.target.value || null)}
							value={selectedLabelPair ?? ''}>
							<option value="">All labels</option>
							<option disabled>---</option>
							{(labelPairs ?? []).map(n => <option key={n} value={n}>{n}</option>)}
						</Form.Control>
					</Form.Group>
					<Col>
						<ResourceUsageBars />
					</Col>
					<Form.Group as={Col}>
						<Form.Label>Sort by:</Form.Label>
						<Form.Control
							as="select"
							onChange={e => setSelectedSortingProperty(e.target.value)}
							value={selectedSortingProperty}>
							<option value="name">Name</option>
							<option value="memory_usage">Memory usage</option>
							<option value="memory_request">Memory request</option>
							<option value="memory_limit">Memory limit</option>
							<option value="cpu_usage">CPU usage</option>
							<option value="cpu_request">CPU request</option>
							<option value="cpu_limit">CPU limit</option>
						</Form.Control>
					</Form.Group>
				</Row>
				<Row sm={1} md={Math.min(2, nodes.length)} lg={Math.min(nodes.length, 3)} xl={Math.min(nodes.length, 4)}>
					{nodes.map((node: NodeEntity) => <Node key={node?.name} node={node} />)}
				</Row>
			</Container>
		</AppContext.Provider>
	);
}

export default App;
