const sortByProp = (selector : (x : any) => string, ascending: boolean = true) => (a : any, b : any) : number => {
	const m = ascending ? -1 : 1;

	if (selector(a) > selector(b)) {
		return -1 * m;
	} else if (selector(a) < selector(b)) {
		return 1 * m;
	}

	return 0;
};

const sortByStringLength = (selector : (x : any) => string = x => x) => (a : any, b : any) : number => {
	if (selector(a).length > selector(b).length) {
		return -1;
	} else if (selector(a).length < selector(b).length) {
		return 1;
	}

	return 0;
}

const ellipsizeText = (str: string, maxLength: number = 50) : string => {
	if (str.length > maxLength) {
		return `${str.substr(0, maxLength - 3)}...`;
	}

	return str;
}

const checkHttpStatus = (res: Response) : Response => {
	if ([200, 201, 204].includes(res.status)) {
		return res;
	}

	throw Error(`${res.status} ${res.statusText}`);
};

const parseResponse = (res: Response) : Promise<any> => checkHttpStatus(res).json();

export { sortByProp };
export { sortByStringLength };
export { ellipsizeText };
export { checkHttpStatus };
export { parseResponse };