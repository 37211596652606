import React, {useContext, useMemo} from 'react';
import {Badge, Form, ProgressBar} from "react-bootstrap";
import PodEntity, {ContainerEntity, ContainerResourceCategory} from "../entity/PodEntity";
import './Pod.scss';
import {
	isPodCompleted,
	roundToDecimalPlaces,
	sumCpu,
	sumMemory
} from "../pod_utils";
import {ellipsizeText, sortByStringLength} from "../utils";
import DeploymentEntity from "../entity/DeploymentEntity";
import AppContext from "../AppContext";
import StatefulSetEntity from "../entity/StatefulSetEntity";
import ms from "ms";
const TimeAgo = require('time-ago');

const NumberOfContainersBadge = ({ pod } : { pod: PodEntity }) => {
	const { containers } = pod;
	const nContainers: number = containers.length;
	const nContainersReady: number = containers.filter((container: ContainerEntity) => container.ready).length;

	if (isPodCompleted(pod)) {
		return <span />;
	}

	if (nContainersReady !== nContainers) {
		return <Badge variant="danger" className="pod-n-containers-badge">{nContainersReady}/{nContainers}</Badge>;
	}

	return <Badge variant="success" className="pod-n-containers-badge">{nContainersReady}/{nContainers}</Badge>;
}

const StatusBadge = ({ pod } : { pod: PodEntity }) => {
	const { containers } = pod;

	const statusCompleted: boolean = isPodCompleted(pod);
	const nContainers: number = containers.length;
	const nContainersReady: number = containers.filter((container: ContainerEntity) => container.ready).length;
	const allContainersReady: boolean = nContainers === nContainersReady;
	const restarts: number = containers.map(c => c.restartCount).reduce((acc, cur) => acc + cur, 0);
	const lastTermination: number | null = useMemo(() => containers.map(c => c.state.terminatedSince ?? c.state.runningSince)
		.filter(x => !!x)
		.reduce((acc, cur) => Math.max(acc, cur), 0) || null, [containers]);
	const lastTerminationHumanReadable = TimeAgo.ago(new Date(lastTermination));
	const recentlyRestarted: boolean = useMemo(() => {
		if (restarts < 1) {
			return false;
		}

		const twentyFourHoursAgo: number = new Date().getTime() - ms('24 hours');
		if (!lastTermination) {
			return false;
		}

		return lastTermination >= twentyFourHoursAgo;
	}, [restarts, lastTermination])

	if (statusCompleted) {
		return <Badge className="pod-status-badge" variant="success">Completed</Badge>;
	} else if (containers.some(c => c.state.waitingReason === 'CrashLoopBackOff')) {
		const tooltip: string = lastTermination ? `Last container termination/start: ${lastTerminationHumanReadable}` : 'Crashed';
		return <Badge title={tooltip} className="pod-status-badge" variant="danger">Crashed</Badge>;
	} else if (containers.some(c => c.state.waitingReason === 'ImagePullBackOff')) {
		return <Badge className="pod-status-badge" variant="danger">Image pull failed</Badge>;
	} else if (!allContainersReady) {
		const tooltip: string = `${nContainersReady}/${nContainers} containers are ready.`;
		return <Badge title={tooltip} className="pod-status-badge" variant="warning">Not ready</Badge>;
	} else if (recentlyRestarted) {
		const tooltip: string = `Last container restart: ${lastTerminationHumanReadable}`;
		return <Badge title={tooltip} className="pod-status-badge" variant="warning">{restarts} restarts</Badge>;
	}

	return <span />;
}

const CpuProgressBar = ({usage, request, limit}: {usage?: number, request?: number, limit?: number}) => {
	if (usage == null) {
		return <span />
	}

	const cpuPercentage = usage * 100.0;
	const upperLimit = limit ?? (50.0 / 100.0);
	const upperLimitPercentage = upperLimit * 100.0;
	const requestPercentage = request * 100.0;
	const percentage = cpuPercentage / upperLimitPercentage * 100.0;

	const style = (() => {
		if (percentage > 90) {
			return 'danger';
		} else if (percentage > 75) {
			return 'warning';
		} else if (request != null ? percentage <= requestPercentage : percentage < 10) {
			return 'info';
		}

		return 'primary';
	})();

	const tooltip: string = `Usage: ${roundToDecimalPlaces(cpuPercentage)}%
Request: ${request == null ? 'Unset' : roundToDecimalPlaces(requestPercentage) + '%'}
Limit: ${limit == null ? 'Unset' : roundToDecimalPlaces(upperLimitPercentage) + '%'}`;

	return <ProgressBar
		title={tooltip}
		striped
		variant={style}
		onClick={() => window.alert(tooltip)}
		now={percentage}
		label={roundToDecimalPlaces(cpuPercentage, 2) + '%'} />;
}
const MemoryProgressBar = ({usage, request, limit, memoryAvailableOnNode}: {usage?: number, request?: number, limit?: number, memoryAvailableOnNode?: number}) => {
	if (usage == null) {
		return <span />
	}

	const mb = usage / 1024.0 / 1024.0;
	const limitMb = limit == null ? null : limit / 1024.0 / 1024.0;
	const maxAvailableToPodIgnoringResourceLimit = memoryAvailableOnNode == null ? null : usage + memoryAvailableOnNode;
	const upperLimit = Math.min(...[limit, maxAvailableToPodIgnoringResourceLimit].filter(x => x != null)) ?? null;
	const upperLimitMb = upperLimit == null ? null : upperLimit / 1024.0 / 1024.0;
	const upperLimitOr512Mb = upperLimitMb ?? 512.0;
	const requestMb = request == null ? null : request / 1024.0 / 1024.0;
	const requestPercentage = requestMb / upperLimitOr512Mb * 100.0; // Percentage of upper limit (or 512MiB)
	const percentage = mb / upperLimitOr512Mb * 100.0;
	const memoryAvailableOnNodeMb = memoryAvailableOnNode == null ? null : memoryAvailableOnNode / 1024.0 / 1024.0;

	const warningLimit = Math.max(upperLimit * 0.85, request, request + ((upperLimit - request) / 2));
	const dangerLimit = Math.max(upperLimit * 0.95, request, warningLimit);

	const style = (() => {
		if (usage >= dangerLimit) {
			return 'danger';
		} else if (usage >= warningLimit) {
			return 'warning';
		} else if (request != null && percentage <= requestPercentage) {
			return 'success';
		}

		return 'primary';
	})();

	const tooltip: string = `Usage: ${roundToDecimalPlaces(mb)}MiB
Request: ${request == null ? 'Unset' : roundToDecimalPlaces(requestMb) + 'MiB'}
Limit: ${limit == null ? 'Unset' : roundToDecimalPlaces(limitMb) + 'MiB'}
Available on node: ${memoryAvailableOnNode == null ? 'Unknown' : roundToDecimalPlaces(memoryAvailableOnNodeMb) + 'MiB'}
Real limit: ${upperLimit == null ? 'Unknown' : roundToDecimalPlaces(upperLimitMb) + 'MiB'}
---
Warning ≥ ${roundToDecimalPlaces(warningLimit / 1024.0 / 1024.0)}MiB
Danger ≥ ${roundToDecimalPlaces(dangerLimit / 1024.0 / 1024.0)}MiB`;

	return <ProgressBar
		title={tooltip}
		variant={style}
		now={percentage}
		onClick={() => window.alert(tooltip)}
		label={roundToDecimalPlaces(mb, 2) + 'MiB'} />;
}

const PodResources = ({pod} : {pod: PodEntity}) => {
	const { availableMemoryByNode } = useContext(AppContext);

	const memoryAvailableOnNode: number | null = availableMemoryByNode?.[pod.nodeName] ?? null;

	const cpuUsage = useMemo(() => sumCpu(pod, ContainerResourceCategory.USAGE), [pod]);
	const memoryUsage = useMemo(() => sumMemory(pod, ContainerResourceCategory.USAGE), [pod]);
	const cpuRequest = useMemo(() => sumCpu(pod, ContainerResourceCategory.REQUEST), [pod]);
	const memoryRequest = useMemo(() => sumMemory(pod, ContainerResourceCategory.REQUEST), [pod]);
	const cpuLimit = useMemo(() => sumCpu(pod, ContainerResourceCategory.LIMIT), [pod]);
	const memoryLimit = useMemo(() => sumMemory(pod, ContainerResourceCategory.LIMIT), [pod]);

	const cpuRatio = cpuUsage / cpuLimit;
	const memoryRatio = memoryUsage / memoryLimit;

	const Cpu = () => <CpuProgressBar usage={cpuUsage} request={cpuRequest} limit={cpuLimit} />;
	const Memory = () => <MemoryProgressBar usage={memoryUsage} request={memoryRequest} limit={memoryLimit} memoryAvailableOnNode={memoryAvailableOnNode} />;

	if (cpuRatio > memoryRatio) {
		return <div className="pod-resources">
			<Memory />
			<Cpu />
		</div>;
	}

	return <div className="pod-resources">
		<Cpu />
		<Memory />
	</div>;
}

const Pod = ({pod} : { pod: PodEntity }) => {
	const { name,
		containers } = pod;
	const { deployments,
		statefulSets,
		podSetNames,
		podsByPodSetName,
		hoverPodSetName,
		setHoverPodSetName } = useContext(AppContext);

	const classNames = ['pod'];

	const nContainers: number = containers.length;
	const nContainersReady: number = containers.filter((container: ContainerEntity) => container.ready).length;
	const allContainersReady: boolean = nContainers === nContainersReady;
	const statusCompleted: boolean = useMemo(() => isPodCompleted(pod), [pod]);
	const deploymentName : string | null = useMemo(() => deployments.map((d: DeploymentEntity) => d.name)
		.sort(sortByStringLength())
		.find((deploymentName: string) => name.startsWith(deploymentName)), [deployments, name]);
	const statefulSetName : string | null = useMemo(() => statefulSets.map((s: StatefulSetEntity) => s.name)
		.sort(sortByStringLength())
		.find((statefulSetName: string) => name.startsWith(statefulSetName)),[statefulSets, name]);
	const podSetName : string | null = useMemo(
		() => podSetNames.find((n: string) => name.startsWith(n)), [podSetNames, name]);
	const podSetHasMultiplePods: boolean = useMemo(
		() => podSetName && podsByPodSetName[podSetName].length > 1,
		[podSetName, podsByPodSetName]);

	if (statusCompleted) {
		classNames.push('completed');
	} else if (allContainersReady) {
		classNames.push('all-containers-ready');
	} else {
		classNames.push('not-ready');
	}

	if (deploymentName) {
		classNames.push('part-of-deployment');
	} else if (statefulSetName) {
		classNames.push('part-of-statefulset');
	}

	if (podSetName === hoverPodSetName) {
		classNames.push('hover-pod-set-name');
	}
	if (podSetHasMultiplePods) {
		classNames.push('pod-set-has-multiple-pods');
	}

	const Label = () => {
		if (podSetName) {
			const podNameSuffix: string = ellipsizeText(name.substr(podSetName.length), 50 - podSetName.length);

			return <span className="pod-name">
				<span className="pod-set-name">{podSetName}</span>
				<span className="pod-name-suffix">{podNameSuffix}</span>
			</span>;
		}

		return <span className="pod-name no-pod-set-name">{ellipsizeText(name, 50)}</span>;
	}

	return <div
		className={classNames.join(' ')}
		onMouseEnter={() => setHoverPodSetName(podSetName ?? null)}
		onMouseLeave={() => {
			if (hoverPodSetName !== podSetName) {
				return;
			}

			setHoverPodSetName(null)
		}}>
		<Form.Switch checked={allContainersReady}
					 readOnly
					 label={<Label />}
					 title={name} />
		{' '}<StatusBadge pod={pod} />
		{!statusCompleted && <div className="pod-second-line">
			<NumberOfContainersBadge pod={pod} />
			<PodResources pod={pod} />
		</div>}
	</div>;
};

export default Pod;
