import KubernetesEntity from "./KubernetesEntity";

export interface ContainerStateEntity {
	running: boolean,
	runningSince?: number,

	terminated: boolean,
	terminatedSince?: number,
	terminatedReason?: string,

	waiting: boolean,
	waitingReason?: string
}

export interface ContainerEntity {
	containerID: string,
	image: string,
	name: string,
	ready: boolean,
	restartCount: number,
	state: ContainerStateEntity,
	resources?: ContainerResources
}

export interface ContainerResources {
	usage?: ContainerMetrics,
	request?: ContainerMetrics,
	limit?: ContainerMetrics
}

export enum ContainerResourceCategory {
	USAGE = 'usage',
	REQUEST = 'request',
	LIMIT = 'limit'
}

export interface ContainerMetrics {
	cpu: number,
	memory: number;
}

export default interface PodEntity extends KubernetesEntity {
	nodeName: string,
	kind?: string,
	hostIP?: string,
	podIP?: string,
	startTime: number,
	metricsWindow: string,
	containers: ContainerEntity[]
};